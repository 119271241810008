<template>
  <div
    class="h-screen flex w-full vx-row no-gutter items-center justify-center page"
    style="color: #626262"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="card-bg page-card">
          <div style="margin-bottom: 25px">
            <img
              src="@/application/assets/images/logo/dnaskills.png"
              style="height: 128px; margin: auto"
            />
          </div>
          <div class="header-text">
            <h2>{{ $l.get("welcome.header") }}</h2>
            <p>{{ $l.get("welcome.body") }}</p>
          </div>
          <div style="margin: 25px"></div>
          <vs-button
            color="primary"
            type="filled"
            size="large"
            @click="onSignUpButtonClick"
            >{{ $l.get("welcome.new-account-button") }}</vs-button
          >
          <div style="margin-top: 30px"></div>
          <vs-divider>{{ $l.get("dictionary.or", "upper") }}</vs-divider>
          <vs-button color="primary" type="flat" @click="onLoginButtonClick">
            {{ $l.get("welcome.login-button") }}
          </vs-button>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import InitWelcomeScreenInteractor from "@/business/welcome/init-welcome-screen";
import SelectSignUpButtonInteractor from "@/business/welcome/select-sign-up";
import SelectLoginButtonInteractor from "@/business/welcome/select-login";
import WelcomeScreenController from "@/adapters/controllers/screen-welcome";

export default {
  screen_name: "welcome",
  data() {
    return {
      controller: null,
      interactors: {
        initWelcomeScreen: null,
        selectSignUpButton: null,
        selectLoginButton: null
      },
      data: null
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(WelcomeScreenController);

    //{ INTERACTORS
    this.interactors.selectLoginButton = this.$injector.get(
      SelectLoginButtonInteractor
    );
    this.interactors.selectSignUpButton = this.$injector.get(
      SelectSignUpButtonInteractor
    );
    this.interactors.initWelcomeScreen = this.$injector.get(
      InitWelcomeScreenInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
  },
  mounted() {
    this.interactors.initWelcomeScreen.handle();
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    onLoginButtonClick() {
      this.interactors.selectLoginButton.handle();
    },
    onSignUpButtonClick() {
      this.interactors.selectSignUpButton.handle();
    }
  }
};
</script>

<style lang="scss" scoped>
.card-bg .vs-button {
  width: 100% !important;
}
.header-text {
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;
}
</style>
